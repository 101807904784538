<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
const request_base_path = require("../src/utils/base_url");
import { getTokenExpTime, getCurrentLoginTime } from "@/utils/auth";
import { getsystemconfig } from "@/api/common";
import { mapState } from "vuex";
import { getSiteInfo } from "@/api/banner";
import { getLinkList } from "@/api/user";
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      isRefresh: false, // 防止连续多次请求
    };
  },
  computed: {
    ...mapState(["websiteInfo", "websiteFooter"]),
  },
  watch: {
    websiteInfo: {
      handler(newV, oldV) {
        this.setTitleIcon(newV);
      },
      immediate: true,
      deep: true,
    },
  },
  created () {
    const that = this
    // const systemConfig = sessionStorage.getItem("systemConfig");
    // if (!systemConfig) {
      that.getSystemConfig()
    // }
    // 本地上传 false 云上传 true
    sessionStorage.setItem("whether", false);
    window.addEventListener(
      "click",
      (e) => {
        // console.log("监听路由跳转");
        if (that.$route.path != "/") {
          const date = new Date(); // 获取当前时间
          const current_time = Math.trunc(date.getTime() / 1000); // 获取当前时间（秒）
          const login_time = Number(getCurrentLoginTime()); // 获取登录时间（秒）
          const token_exp_time = Number(getTokenExpTime()); // 获取Token有效期（秒）
          const expiration = login_time + token_exp_time; // 计算Token有效期截止时间（秒）
          // 判断是否登录
          if (expiration) {
            const diff_time = current_time - expiration; // 计算当前到截止时间的距离
            // console.log("计算时间", diff_time);
            // Token 更新
            if (
              diff_time < 0 &&
              Math.abs(diff_time) < 10 * 60 &&
              !this.isRefresh
            ) {
              this.isRefresh = true;
              // console.log("Token 更新");
              that.$store
                .dispatch("refreshToken")
                .then((res) => {
                  // console.log("Token 更新成功");
                  that.isRefresh = false;
                })
                .catch((err) => {
                  that.isRefresh = false;
                });
            } else {
              // console.log("Token 不更新");
            }
          }
        }
      },
      true
    );
    // debugger
    that.initSetInfo();
    if (!Object.keys(that.websiteInfo).length) {
      that.initSetInfo();
    }
    if (!Object.keys(that.websiteFooter).length) {
      that.initLinkList();
    }
  },
  mounted() {
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    window.addEventListener("unload", (e) => this.unloadHandler(e));
  },
  beforeDestroy() {
    window.localStorage.removeItem("menuId");
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) =>
      this.beforeunloadHandler(e)
    );
    window.removeEventListener("unload", (e) => this.unloadHandler(e));
  },
  methods: {
    beforeunloadHandler() {
      this._beforeUnload_time = new Date().getTime();
    },
    unloadHandler(e) {
      this._gap_time = new Date().getTime() - this._beforeUnload_time;
      //判断是窗口关闭还是刷新
      if (this._gap_time <= 5) {
        //如果是登录状态，关闭窗口前，移除用户
        if (!this.showLoginButton) {
          localStorage.removeItem("menuId");
        }
      }
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    setTitleIcon(data) {
      let link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.href = data.portal_url_pic;
      document.getElementsByTagName("head")[0].appendChild(link);
      document.getElementsByTagName("title")[0].innerText =
        data.portal_name + "-门户";
    },
    async getSystemConfig() {
      await getsystemconfig().then((res) => {
        if (res.code === 0 && res.data) {
          console.log(res.data);
          let systemConfig = JSON.stringify(res.data);
          sessionStorage.setItem("systemConfig", systemConfig);
        }
      });
    },
    async initSetInfo() {
      await getSiteInfo()
        .then((res) => {
          if (res.code === 0 && res.data) {
            this.$store.commit("SET_Website_Info", res.data);
            localStorage.setItem("schoolid", res.data.school_id);
          }
        })
        .catch((error) => {});
    },
    async initLinkList() {
      await getLinkList()
        .then((res) => {
          if (res.code === 0 && res.data) {
            this.$store.commit("SET_Website_Footer", res.data);
          }
        })
        .catch((error) => {});
    },
  },

  // watch: {
  //   "$route": {
  //     handler(val) {
  //       if (val.path == '/' || val.path == '/login') {
  //         // 开发环境
  //         if (process.env.NODE_ENV == 'development') {
  //          // location.href = request_base_path.dev_url + 'cas-login?fromtype=3&redirect_url=' + request_base_path.dev_url + '#/sign_on'
  //         } else if (process.env.NODE_ENV == 'production') {
  //           // 生产环境
  //           location.href = request_base_path.pro_url + 'cas-login?fromtype=3&redirect_url=' + request_base_path.pro_url + '#/sign_on'
  //         }
  //       }
  //     },
  //     immediate: true
  //   }
  // }
};
</script>
<style>
html,
body {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}

#app {
  width: 100%;
  height: 100%;
  padding: 0px;
  margin: 0px;
}
</style>
